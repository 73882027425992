/* Global Values */
/* Custom Fonts */
@font-face {
    font-family: Franklin Gothic Book;
    src: url('/Content/fonts/FranklinGothicBook.ttf') format('truetype');
}

@font-face {
    font-family: Franklin Gothic Demi;
    src: url('/Content/fonts/FranklinGothicDemi.ttf') format('truetype');
}

@font-face {
    font-family: MyriadPro-Bold;
    src: url('/Content/fonts/MyriadPro-Bold.otf') format('opentype');
}

@font-face {
    font-family: MyriadPro-Regular;
    src: url('/Content/fonts/MyriadPro-Regular.otf') format('opentype');
}

.section.w-section {
    /*padding-top: 25px;*/
    padding-bottom: 25px;
}

.add-to-bag {
    display: none;
}
/* Bootstrap overides */
ul {
    margin-left: 15px;
}

p {
    margin: 0;
}
/* General Styles */
body {
    font-family: 'Franklin Gothic Book';
    font-size: 18px;
    line-height: 30px;
    color: #062e57;
}

.body-bold {
    font-family: 'Franklin Gothic Demi';
    font-size: 18px;
    line-height: 30px;
}

h1 {
    font-family: 'Franklin Gothic Demi';
    font-size: 24px;
    text-transform: uppercase;
    color: #062e57;
    margin-top: 0;
    margin-bottom: 5px;
}

h2 {
    font-family: MyriadPro-Bold;
    font-size: 30px;
    text-transform: uppercase;
}

.nav {
    font-family: 'Franklin Gothic Demi';
    font-size: 15px;
}

.footer {
    font-family: 'Franklin Gothic Book';
    font-size: 14px;
    line-height: 24px;
    color: #5591cd;
}

.slogan {
    font-family: MyriadPro-Regular;
    font-size: 24px;
    color: #062e57;
    margin-top: 18px;
}

.phone {
    font-family: MyriadPro-Bold;
    font-size: 31px;
    color: #b4d334;
}

.call-to-action {
    font-family: 'Franklin Gothic Book';
    font-size: 24px;
    line-height: 24px;
}

.product-details {
    font-family: 'Franklin Gothic Book';
    font-size: 17px;
    line-height: 24px;
}

.product-price {
    font-family: 'Franklin Gothic Book';
    font-size: 17px;
}

.product-price-large {
    font-family: 'Franklin Gothic Demi';
    font-size: 24px;
}

.table td {
    font-family: 'Franklin Gothic Book';
    font-size: 16px;
}

.tabs {
    font-family: 'Franklin Gothic Book';
    font-size: 18px;
}

.block-element {
    margin-bottom: 30px;
}

a.green-button {
    display: block;
    width: 166px;
    height: 41px;
    line-height: 41px;
    background-color: #b4d334;
    text-align: center;
    color: #062e57;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.carousel .container {
    position: relative;
}

.carousel-control {
    opacity: 1;
}

    .carousel-control.left,
    .carousel-control.right {
        background-image: none;
    }

    .carousel-control span {
        background-color: #062e57;
        opacity: 1;
    }

    .carousel-control .icon-prev,
    .carousel-control .glyphicon-chevron-left {
        height: 30px;
        left: 10px;
        line-height: 28px;
        width: 30px;
    }

    .carousel-control .icon-prev,
    .carousel-control .glyphicon-chevron-right {
        height: 30px;
        right: 10px;
        line-height: 28px;
        width: 30px;
    }

    .carousel-control:hover,
    .carousel-control:focus {
        opacity: 1;
    }

.centered-col-images img {
    margin: auto;
}

.the-website.left-menu-visible {
    -moz-box-shadow: -5px 0 15px 0 rgba(50, 50, 50, 0.75);
    -webkit-box-shadow: -5px 0 15px 0 rgba(50, 50, 50, 0.75);
    box-shadow: -5px 0 15px 0 rgba(50, 50, 50, 0.75);
}

h1.underlined {
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.site-table table {
    width: 100%;
    border-spacing: 2px;
    border-collapse: separate;
}

    .site-table table th {
        font-weight: normal;
        text-transform: uppercase;
        background-color: #0a4886;
        color: #fff;
        padding: 4px 20px;
    }

    .site-table table td {
        color: #fff;
        padding: 4px 20px;
    }

    .site-table table tr:nth-child(even) {
        background-color: #0a4886;
    }

    .site-table table tr:nth-child(odd) {
        background-color: #062e57;
    }

.site-table.table-text-center th,
.site-table.table-text-center td {
    text-align: center;
}
/* Header */
#header {
    height: 145px;
}

img.logo {
    margin-top: 41px;
}

.mobile-menu-trigger {
    width: 36px;
    background-color: #062e57;
    color: #fff;
    text-align: center;
    margin-top: 50px;
    padding-top: 3px;
    height: 33px;
}

    .mobile-menu-trigger:hover {
        color: #b4d334;
    }

    .mobile-menu-trigger span {
        font-size: 24px;
    }

.social-icons {
    margin-bottom: 10px;
    margin-top: 37px;
    margin-left: 0;
}

.header-info {
    margin-bottom: 10px;
}

.social-icons li {
    padding: 0;
    margin: 0;
    list-style: none;
    float: left;
    margin-right: 10px;
}

.navigation-wrapper {
    background-color: #062e57;
    line-height: 50px;
}

    .navigation-wrapper ul {
        margin: 0;
    }

.nav-list {
    font-family: 'Franklin Gothic Demi';
    font-size: 15px;
    color: #fff;
}

    .nav-list a {
        color: #fff;
    }

#site-search-btn,
#signup-btn,
#prod-search-btn {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #b4d334;
    border-color: #b4d334;
    -moz-border-image: none;
    -o-border-image: none;
    -webkit-border-image: none;
    border-image: none;
    border-style: solid solid solid none;
    border-width: 1px 1px 1px medium;
    color: #062e57;
    display: block;
    font-size: 14px;
    height: 30px;
    text-align: center;
    width: 34px;
}

#header input.form-control {
    border: 1px solid #062e57;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    height: 30px;
    background-color: #062e57;
    color: #fff;
}

#header-bg {
    background-image: url("/Content/site-images/custom/grass.png");
    background-repeat: repeat-x;
    background-position: bottom;
}

.navigation-wrapper-mobile {
    height: 10px;
    width: 100%;
    background-color: #062e57;
}
/* Navigation */
#main-nav > li {
    padding: 0 20px;
}

    #main-nav > li:first-child {
        padding-left: 0;
    }

#main-nav a:hover,
#main-nav a:active,
#main-nav a:focus {
    text-decoration: none;
    color: #b4d334;
}

#main-nav .dropdown-nav {
    background-color: #062e57;
    margin-left: -20px;
    padding: 10px 0;
    min-width: 150px;
}

    #main-nav .dropdown-nav li {
        padding: 0 10px;
    }

        #main-nav .dropdown-nav li a {
            display: block;
            width: 100%;
        }

    #main-nav .dropdown-nav li {
        line-height: 25px;
    }

#main-nav .arrow {
    font-size: 13px;
}

.navigation-wrapper .dropdown-nav.dropdown-level1 {
    -moz-box-shadow: 15px 5px 15px -5px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 15px 5px 15px -5px rgba(0, 0, 0, 0.5);
    box-shadow: 15px 5px 15px -5px rgba(0, 0, 0, 0.5);
}
/* Mobile Left Navigation */
ul#mobile-left {
    margin: 0;
    width: 80%;
}

    ul#mobile-left ul {
        margin: 0;
    }

#mobile-left li {
    font-family: 'Franklin Gothic Demi';
    font-size: 15px;
}

    #mobile-left li a {
        color: #062e57;
        display: block;
        padding: 5px 40px;
        width: 100%;
    }

        #mobile-left li a:hover,
        #mobile-left li a:focus {
            text-decoration: none;
            background-color: #062e57;
            color: #fff;
        }

        #mobile-left li a.sublink-open {
            background-color: #062e57;
            color: #fff;
            text-decoration: none;
        }

#mobile-left > li {
    background-color: #b4d334;
}

    #mobile-left > li > ul {
        background-color: #fff;
    }
/* Footer */
.footer-wrapper {
    background-color: #062e57;
    padding: 50px 0;
    -moz-box-shadow: 0px 500px 0px 500px #062e57;
    -webkit-box-shadow: 0px 500px 0px 500px #062e57;
    box-shadow: 0px 500px 0px 500px #062e57;
}

.footer-img {
    background-image: url("/Content/site-images/custom/grass.png");
    background-repeat: repeat-x;
    background-position: bottom;
    height: 40px;
}
/* Home */
.main-banner {
    background-image: url("/Content/site-images/custom/banner-bg.jpg");
    background-size: cover;
    height: 400px;
}

    .main-banner .carousel-inner {
        text-align: center;
    }

    .main-banner .container {
        padding: 0;
    }

        .main-banner .container img {
            display: inline;
            left: 100%;
            margin-left: -200%;
            min-height: 400px;
            min-width: 768px;
            position: relative;
        }

.call-to-action-wrapper {
    background-color: #062e57;
    color: #fff;
    padding: 40px 0;
}

.mbss-banner-text {
    background-color: #000;
    color: #b4d334;
    font-family: MyriadPro-Bold;
    font-size: 23px;
    margin-top: 235px;
    padding: 10px;
    text-align: left;
    text-transform: uppercase;
    width: 70%;
}
/* Products */
#product-variants-table tr td:nth-child(3),
#product-variants-table tr td:nth-child(4),
.price {
    display: none;
}

#product-variants-table tr td:nth-child(2) {
    padding-left: 20px;
}

.featured-products a.product-title {
    color: #062e57;
    font-family: 'Franklin Gothic Demi';
    font-size: 18px;
    line-height: 30px;
}

.prod-img,
.cat-img {
    height: 215px;
    line-height: 215px;
}

    .prod-img img,
    .cat-img img {
        max-height: 215px;
        display: inline-block;
    }

.product-list-item {
    height: 335px;
}

    .product-list-item a {
        line-height: 20px;
    }

h1 a {
    color: #062e57;
}

    h1 a:hover {
        color: #062e57;
    }

#product-list h1,
h1.product-detail-title {
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 5px;
    margin-top: 0;
}

.product-code {
    font-style: italic;
    font-size: 14px;
    margin-bottom: 5px;
}

.category-list-item a {
    font-family: 'Franklin Gothic Demi';
    font-size: 18px;
    line-height: 30px;
    color: #062e57;
}

.breadcrumb {
    background-color: #fff;
    font-size: 14px;
    margin-bottom: 0;
    color: #0a4886;
    margin-top: 2%;
}

    .breadcrumb a {
        color: #0a4886;
    }

.product-title a {
    font-family: 'Franklin Gothic Demi';
    font-size: 18px;
    line-height: 30px;
    color: #062e57;
}

.breadcrumb-item {
    list-style: none;
}

.tab-content {
    padding-top: 20px;
}

    .tab-content .prod-img {
        height: 175px;
        line-height: 175px;
    }

        .tab-content .prod-img img {
            max-height: 175px;
            display: inline-block;
        }

#other-products-holder .nav-tabs li a {
    color: #062e57;
    font-weight: normal;
    font-family: 'Franklin Gothic Book';
    font-size: 18px;
}

#other-products-tabs {
    margin: 0;
}

.product-view-price-holder {
    font-family: 'Franklin Gothic Demi';
    font-size: 24px;
    margin-top: 15px;
}

#product-list h2 {
    display: none;
}

.product-list-control-bar {
    background-color: #ebebeb;
}

    .product-list-control-bar select,
    .product-list-control-bar label {
        font-size: 16px;
        font-weight: normal;
    }

.sub-cat-list-holder {
    text-align: center;
}

    .sub-cat-list-holder a {
        font-family: 'Franklin Gothic Demi';
        font-size: 18px;
        line-height: 30px;
        color: #062e57;
    }

.sub-cat-list-image-holder {
    height: 200px;
    line-height: 200px;
}

    .sub-cat-list-image-holder img {
        margin: auto;
        display: inline-block;
    }

.product-list-page-links {
    float: right;
    margin-left: 5px;
}

    .product-list-page-links li a {
        color: #0a4886;
        display: block;
        width: 100%;
    }

    .product-list-page-links li {
        list-style: none;
        float: left;
        width: 20px;
        text-align: center;
        font-size: 16px;
    }

        .product-list-page-links li .glyphicon {
            font-size: 14px;
        }

        .product-list-page-links li.current-page {
            text-decoration: underline;
        }

.price-old {
    text-decoration: line-through;
}

.price-sale {
    color: #ff0000;
}

.product-mini-images {
    padding-top: 10px;
}

#videoList {
    padding-top: 5px;
}

.ytPopBtn {
    position: relative;
}

.vidPlayBtn {
    left: 27px;
    position: absolute;
    top: -23px;
}
/* Contact Page */
.contact-form .form-control {
    margin-bottom: 15px;
    margin-top: 20px;
    background-color: #062e57;
    color: #fff;
}
/* Meet the team */
.meet-the-team-item.mbss-listItem {
    margin-bottom: 40px;
    clear: both;
}

    .meet-the-team-item.mbss-listItem .mbss-listItem-image {
        width: 125px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }

    .meet-the-team-item.mbss-listItem div:nth-child(2) {
        font-family: 'Franklin Gothic Demi';
        font-size: 18px;
        line-height: 30px;
    }
/*Animation Overview*/
.animationWrapper iframe {
    width: 100%;
    min-height: 500px;
}

.ui-autocomplete li {
    list-style: none;
    cursor: pointer;
    font-size: 14px;
}

.ui-autocomplete .ui-state-focus {
    color: #000;
}

img {
    max-width: 100%;
}

input.green-button {
    background-color: #b4d334;
    color: #062e57;
    display: block;
    height: 41px;
    line-height: 41px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    text-align: center;
    width: 166px;
    border: none;
}
/* Size specific */
@media (min-width: 768px) {
    #header {
        height: auto;
    }

    img.logo {
        margin-top: 31px;
        margin-left: 0;
    }

    .main-banner .container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .carousel-control .icon-prev,
    .carousel-control .glyphicon-chevron-left {
        height: 47px;
        left: 30px;
        line-height: 47px;
        width: 47px;
    }

    .carousel-control .icon-prev,
    .carousel-control .glyphicon-chevron-right {
        height: 47px;
        right: 30px;
        line-height: 47px;
        width: 47px;
    }

    .mbss-banner-text {
        font-family: MyriadPro-Bold;
        font-size: 30px;
        text-transform: uppercase;
        color: #b4d334;
        background-color: #000;
        margin-top: 40px;
        padding: 10px 40px;
        width: 430px;
    }

    a.green-button {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
    }

    .meet-the-team-item.mbss-listItem .mbss-listItem-image {
        width: 125px;
        float: right;
    }

    .meet-the-team-item.mbss-listItem div:nth-child(2) {
        font-family: 'Franklin Gothic Demi';
        font-size: 18px;
        line-height: 30px;
        margin-right: 145px;
    }

    .meet-the-team-item.mbss-listItem div:nth-child(3) {
        margin-right: 145px;
    }

    #main-nav > li {
        padding: 0 10px;
    }
}

@media (min-width: 992px) {
    #main-nav > li {
        padding: 0 20px;
    }
}

@media (min-width: 1200px) {
    .call-to-action {
        padding: 0 125px;
    }
}
/*Fix after update 6.2.7*/
.slogan {
    text-transform: none;
}
.slide {
    margin-top:0px;
}
 ul {
    padding-left:0px;
}
@media (min-width: 768px) {
    .hidden-xs {
        display: block;
    }
}

h2, h3 {
    line-height: 1.1;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 500;
}
h3 {
    font-size: 24px;
    font-family: inherit;
    color: inherit;
}
h4 {
    line-height: 1.1;
    font-weight: 500;
}
.w-tab-link.w--current {
    background-color: white;
}
.w-tab-link {
    background-color: white;
}
@media (min-width: 992px) {
    .w-container {
        width: 970px !important;
    }
}

@media (min-width: 768px) {
    .w-container {
        width: 750px !important;
    }
    .w-col-3 {
        width: 25%;
        float:left;
    }
}
@media (min-width: 1200px) {
    .w-container {
        width: 1170px !important;
    }
}

.w-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.w-row {
    margin-left: -15px;
    margin-right: -15px;
}
.block-element {
    margin-bottom: 30px;
}
.w-col-6 {
    width: 50%;
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.product-tab-menu {
    padding-top: 10px;
}
h1.product-detail-title {
    padding-bottom: 5px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 5px;
    margin-top: 0;
}
.product-detail-price {
    font-family: 'Franklin Gothic Demi';
    font-size: 24px;
    margin-top: 15px;
}
.price-sale {
    color: #f00;
}
.price-old {
    text-decoration: line-through;
}
.w-col-3 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.product-name  {
    font-family: 'Franklin Gothic Demi';
    font-size: 18px;
    line-height: 30px;
    color: #062e57;
}
.product-image {
    max-height: 175px;
    max-width: 100%;
    height: auto;
}
.w-slider {
    height: 100%;
    /*margin-left: 5%;
    margin-right: 5%;*/
    background:none;
}
@media (min-width: 768px) {
    .w-slider-arrow-left .icon-prev, .w-slider-arrow-left .glyphicon-chevron-right {
        height: 47px;
        right: 30px;
        line-height: 47px;
        width: 47px;
    }
}
.hidearrow {
    opacity: 1;
}
.glyphicon.glyphicon-chevron-left, .glyphicon.glyphicon-chevron-right{
    background-color: #062e57;
    padding: 15%;
}
.w-slider-mask {
    z-index:0;
}
.w-slide .w-container {
width:initial!important;
}
.w-input, .w-select {
    display: initial;
    width: initial;
    height:initial;
    padding: 0;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 1.428571429;
    color: #333;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ccc;
}
label {
    display: inline-block !important;
    font-size: 16px;
    font-weight: normal;
}
.filter-select-wrapper {
    background-color: #ebebeb;
    margin-bottom: 10px;
    margin-top:50px;
}
.w-form {
margin:0;
}
.pageno {
    display: inline-block;
    padding: 0;
    background-color: transparent; 
    color:initial;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    border-radius: 0;
}
.pagination {
    background-color: #ebebeb;
    padding-left: 15px;
    padding-right: 15px;
}
.product-detail-bottom-tabs .w-tab-menu {
    border-bottom: 1px solid #ddd;
}
.product-detail-bottom-tabs .w-tab-link {
    border: 1px solid #ddd;
    border-bottom: initial;
    margin-bottom: -1px;
}
.tabtext {
    color: #062e57;
    font-weight: normal;
    font-family: 'Franklin Gothic Book';
    font-size: 18px;
}
@media (max-width: 991px) {
    .Title {
        display: none;
    }
}
@media (min-width: 991px) {
    .TitleMob {
        display: none;
    }
}
ul li {
    margin-left: 0;
    list-style: none;
}
ul#mobile-left {
    margin: 0;
    width: 80%;
    background-color: #b4d334;
}
@media screen and (max-width: 767px) {
    .w-col {
        width: 100%;
        left: auto;
        right: auto;
    }
}
.product-detail-price2 {

}
.variant-table {
display:flex;
}
.variant-table .description {
margin-right:20px;    
    }
.featuredwrapper {
display:flex;
flex-wrap:wrap;
}
.w-slider-arrow-left {
left:-4px;
}
.w-slider-arrow-right {
right:-4px;
}