/* Global Settings for Core */
/* Import navigation core */
/* MAIN NAVIGATION */
ul#main-nav.navigation.justify {
  text-align: justify;
  min-width: 500px;
}
ul#main-nav.navigation.justify:after {
  content: '';
  display: inline-block;
  width: 100%;
}
ul#main-nav.navigation.justify > li {
  display: table-cell;
  width: 1%;
  float: none;
}
ul#main-nav.navigation > li {
  text-align: center;
  float: left;
}
ul#main-nav.navigation > li > ul li {
  text-align: left;
}
ul#main-nav.navigation li {
  list-style: none;
}
ul#main-nav.navigation > li > ul {
  position: absolute;
  z-index: 9999;
}
ul#main-nav.navigation .nav-col {
  float: left;
}
/* COMMON */
ul.navigation .arrow.arrow-up {
  display: none;
}
ul.navigation .dropdown-nav {
  display: none;
}
/* MOBILE LEFT NAVIGATION */
ul#mobile-left {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}
/* MOBILE TOP NAVIGATION */
ul#mobile-top {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
a:focus {
  outline: none;
}
/* bootsrap overrides and generic classes*/
.breadcrumb {
  margin-bottom: 0px;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-justify {
  text-align: justify;
}
.hidden-xs {
  display: none;
}
.visible-xs {
  display: table-cell;
}
.visible-xs.block {
  width: 100%;
  display: inline-block;
}
.core-hidden {
  display: none;
}
ul {
  padding: 0px;
  margin: 0px;
}
ul li {
  margin-left: 0;
  list-style: disc inside;
}
ul.ui-autocomplete li {
  list-style: none;
}
.w-slide {
  /*background-size: cover!important;*/
  background-position: 50% 50%!important;
  background-repeat: no-repeat !important;
}
.embed-container {
  padding-bottom: 56.25%;
  height: 100%;
  max-width: 100%;
  display: block;
  /*height: 800px;*/
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #000;
}
.begin-wrapper {
  display: none;
}
/* BANNER LAYERS RESPONSIVE */
.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  font-size: 15px;
}
.banner-drop-options {
  display: none;
}
/* add fade class to carousel to make transitions fade through rather than scroll */
.carousel.banner-fade .carousel-inner .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.carousel.banner-fade .carousel-inner .active {
  opacity: 1;
}
.carousel.banner-fade .carousel-inner .active.left,
.carousel.banner-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel.banner-fade .carousel-inner .next.left,
.carousel.banner-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel.banner-fade .carousel-control {
  z-index: 2;
}
.carousel-fade .carousel-inner .item {
  opacity: 0;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
/* Mobile First */
.the-website {
  position: relative;
}
#scrollUp {
  background-image: url(../../../../../Content/site-images/master/scrollTop.jpg);
  bottom: 10px;
  color: #FFFFFF;
  height: 38px;
  width: 38px;
  right: 10px;
  opacity: 0.8;
}
#scrollUp.move-up-cookie {
  bottom: 30px;
}
.mobile-menu-trigger span {
  font-size: 20px;
  cursor: pointer;
}
#bag {
  position: relative;
}
#bag li {
  list-style: none;
}
#order-drop-wrapper {
  position: absolute;
}
img.not-selected {
  display: none;
}
img.thumb-img {
  cursor: pointer;
}
.product-main-img-holder {
  height: 600px;
}
#ytPop {
  background-color: #000;
  width: 600px;
  height: 450px;
  position: relative;
}
#videoList li {
  text-align: left;
  width: 120px;
  height: 90px;
}
#videoList li:hover {
  opacity: 0.9;
}
#videoList li .video-thumb {
  position: absolute;
}
#videoList li .ytPopBtn {
  position: relative;
}
#videoList li .vidPlayBtn {
  position: absolute;
  left: 20px;
  top: 20px;
}
.country-dropdown {
  width: 150px;
}
#info ul,
.postContent ul {
  margin-left: 14px;
}
#info li,
.postContent ul li {
  list-style: disc;
}
.new-icon {
  position: absolute;
}
.upper-case,
.text-uppercase {
  text-transform: uppercase;
}
.padded-top {
  padding-top: 30px;
}
.mbss-banner-layer {
  position: absolute;
  top: 0px;
  left: 0px;
}
/* Product View */
.nav-tabs {
  font-size: 12px;
}
#main-image-wrapper a {
  cursor: zoom-in;
}
.variantRow
{
    display:none;
}
/* Cart Global */
.cart-table th {
    text-transform: uppercase;
    white-space: nowrap;
}

th.cart-code,
th.cart-price,
th.cart-retail-price,
th.cart-qty,
th.cart-remove,
th.cart-total-ex-tax,
th.cart-total-tax,
th.cart-total-inc-tax {
    display: none;
}

td.cart-code,
td.cart-price,
td.cart-retail-price,
td.cart-qty,
td.cart-remove,
td.cart-total-ex-tax,
td.cart-total-tax,
td.cart-total-inc-tax {
    display: none;
}

th.top-cart-footer-code,
th.top-cart-footer-description,
th.top-cart-footer-retail-price,
th.top-cart-footer-price,
th.top-cart-footer-qty,
th.top-cart-footer-total-ex-tax,
th.top-cart-footer-total-tax,
th.top-cart-footer-total-inc-tax {
    display: none;
}

th.checkout-cart-footer-padding-code,
th.checkout-cart-footer-padding-retail-price,
th.checkout-cart-footer-padding-price,
th.checkout-cart-footer-padding-qty,
th.checkout-cart-footer-padding-remove,
th.checkout-cart-footer-padding-total-ex-tax,
th.checkout-cart-footer-padding-total-tax {
    display: none;
}

p.cart-code,
p.cart-price,
p.cart-retail-price,
p.cart-stock,
p.cart-total-combined {
    font-style: italic;
    margin-top: 0.1em;
    margin-bottom: 0.2em;
}

p.cart-qty {
    margin-top: 15px;
    margin-bottom: 0;
}

select.cart-qty.qty-tbx {
    margin: 0 auto;
}

td.cart-description {
    text-align: left;
}

th.checkout-cart-footer-row-label {
    text-align: right;
}

/* Top Cart */
#top-cart {
    margin-top: 20px;
    margin-bottom: 0;
}

#bag-closer {
    float: right;
}

#top-cart-below .proceed {
    margin-top: 20px;
    margin-right: 0;
    margin-bottom: 20px;
}

/* Checkout */
.checkout-discount-area {
    margin-top: 20px;
}

.order-total-label,
.order-total-value {
    line-height: 40px;
    margin-top: 0;
    margin-bottom: 0;
}

textarea.input {
    padding-top: 12px;
    padding-bottom: 12px;
}

.qtywrapper.qtyWrapperDesktop {
  display: none;
}
.qtywrapper.qtyWrapperMobile {
  display: inline-block;
}
.cart-table img {
  margin-left: auto;
  margin-right: auto;
}
.table.cart-table {
    width: 100%;
    text-align: center;
}
#top-cart-holder {
  margin: 15px;
  display: none;
}
table.cart-table th {
  font-size: 13px;
}
table.cart-table tbody > tr > td,
table.cart-table tbody > tr > th {
  padding: 10px;
}
#billing,
#delivery {
  font-size: 12px;
}
.checout-btn {
  display: inline-block;
}
#delivery-options-frame h4 {
  margin-top: 0;
}
.tcLabel,
.paymentLabel {
  display: inline-block;
  margin-bottom: 20px;
}
.deliveryCost {
  padding-left: 10px;
  padding-right: 10px;
}
#validNotes {
  float: right;
  margin-bottom: 15px;
  margin-top: 15px;
  color: #a82031;
}
.payment-confirmation-page {
  margin-top: 30px;
  margin-bottom: 30px;
}
#billing,
#delivery {
  padding-left: 10px;
  padding-right: 10px;
}
.delivery h4 {
  text-align: left;
}
#tc {
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}
#discount-input {
  width: 250px;
  position: relative;
}
#discount-btn {
  position: absolute;
  right: 0px;
  top: 8px;
  padding: 0;
  margin-top: 0;
}
#voucher-not-found {
  display: none;
}
.paypalAnnotation {
  margin-top:10px;
  display: block;
}
.delivery-options {
    margin-bottom: 30px;
}
.delivery-options ul li {
  list-style: none;
}
/* blog */
.post-share-buttons {
  padding: 20px 0;
}
.fb-share-wrapper {
  float: left;
  margin-right: 15px;
}
.twitter-share-wrapper {
  float: left;
  margin-right: 15px;
}
.google-share-wrapper {
  overflow: auto;
  float: left;
}
.mbss-image-scale {
  max-width: 100%;
  height: auto;
}
/*cookie bar*/
#cookie-bar {
  background: #3D3D3D !important;
  padding: 5px 40px !important;
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 22px !important;
}
#cookie-bar a.cookie-link {
  color: #FFF;
  text-decoration: underline;
  padding: 0;
  margin-left: 0;
}
#cookie-bar a.cb-enable {
  padding: 3px 8px;
}
#cookie-bar .cb-enable:hover {
  background: #119F9B none repeat scroll 0% 0% !important;
}
#cookie-bar .cb-enable {
  background: #008F8A none repeat scroll 0% 0% !important;
}
/* product filter */
.cat-selected {
  font-weight: bold;
  text-decoration: underline;
}
.filter-dropdown-icon, .spec-filter-dropdown-icon{
  -moz-transition: transform 500ms ease 0s;
  -o-transition: transform 500ms ease 0s;
  -webkit-transition: transform 500ms ease 0s;
  transition: transform 500ms ease 0s;
}
.filter-dropdown-icon.closed, .spec-filter-dropdown-icon.closed{
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

#filter .filter-dropdown-icon, #filter .spec-filter-dropdown-icon{
    display:inline-block;
}
/*#filter .subdropdown-toggle .filter-dropdown-icon{
    display:none;
}*/
#filter .filter-subdropdown-list-2{
    padding-left:15px;
}

#filter .cat-selected{
    font-weight:bold;
}

#filter .filter-brands, #filtersOnly .filter-specs{
    display:none;
}

/* product list and view */
.productOptions.highlight {
  border: 2px solid #d00000;
}
.productOptionWarn {
  color: #d00000;
  display: none;
}
.socialwrapper > div {
  float: left;
  margin-right: 10px;
}
.socialwrapper .social.fb {
  top: 0;
}
.socialwrapper .social.googleplus {
  top: 0;
}
.socialwrapper .social.pinterest {
  top: -3px;
}
.post-share-buttons > div {
  float: left;
  margin-right: 10px;
  position: relative;
}
.post-share-buttons .pinterest-share-wrapper {
  top: -3px;
}

a.platform-container-link
{
    position:absolute;
    display:block;
    width:100%;
    height:100%;
}

/* Parts viewer button */
input.button-pv-cart {
    font-size: 12px;
    padding: 5px 2px;
    min-width: 110px;
    margin: 0;
}

html input.w-button.button-pv-cart {
    border-style: solid;
    border-width: 2px;
}

/* Modal login styles */
#modal-login-warning{
    display:none;
}
#login-modal .w-button {
    float: left;
}
.forgotten-password {
    margin-bottom: 10px;
    margin-left: 20px;
    float: right;
}

/* Errors */
.errormessage-block {
    padding: 5px 10px;
    margin-bottom: 15px;
    background-color: rgba(160, 0, 0, 0.8);
    color: white;
}
.errormessage-block h1,
.errormessage-block h2,
.errormessage-block h3,
.errormessage-block h4 {
    margin: 0.3em 0;
}

/* checkout address lookup */
.postcode-lookup-input{
    clear: both;
    max-width: 300px;
    text-transform:uppercase;
}
.postcode-lookup-btn{
    clear: both;
    max-width: 300px;
    width:100%;
}
.postcode-lookup-dropdown{
    margin-top:10px;
}
input.validity-erroneous{
    border:2px solid #a82031 !important;
}
#makePaymentBtn{
    width:100%;
    max-width: 300px;
}

.checkout-signin-actions a{
    width:100%;
    margin-top:20px;
    text-align:center;
}
#checkout-login .reset-pass-btn{
    float:right;
}
.guest-checkout-panel{
    margin-top:40px;
    margin-bottom:40px;
}


/* Size dependant styles */
/* Bootstrap 'sm' size, tablets portrait */
@media (min-width: 768px) {
  .qtywrapper.qtyWrapperDesktop {
    display: inline-block;
  }
  .qtywrapper.qtyWrapperMobile {
    display: none;
  }
  .hidden-xs {
    display: block;
  }
  .visible-xs {
    display: none;
  }
  .product-nav {
    width: 100%;
    position: absolute;
    left: 0px;
    margin: 0px;
    padding: 0px;
  }

  /* Product View */
  .nav-tabs {
    font-size: inherit;
  }

  /* Cart Global */
  th.cart-total-combined {
      display: none;
  }

  td.cart-total-combined {
      display: none;
  }

  th.top-cart-footer-total-combined {
      display: none;
  }

  th.cart-total-ex-tax,
  th.cart-total-tax,
  th.cart-total-inc-tax {
      display: table-cell;
  }

  td.cart-total-ex-tax,
  td.cart-total-tax,
  td.cart-total-inc-tax {
      display: table-cell;
  }

  th.top-cart-footer-total-ex-tax,
  th.top-cart-footer-total-tax,
  th.top-cart-footer-total-inc-tax {
      display: table-cell;
  }

  th.checkout-cart-footer-padding-total-ex-tax,
  th.checkout-cart-footer-padding-total-tax {
      display: table-cell;
  }

  /* Checkout */
  table.cart-table tbody > tr > td,
  table.cart-table tbody > tr > th {
    padding: 15px;
  }
  #billing,
  #delivery {
    font-size: inherit;
  }
  #checkout-login .sign-in-panel{
      padding-right:60px;
  }
  #checkout-login .guest-checkout-panel{
      padding-left:60px;
  }

    .checkout-signin-actions a{
        width:auto;
    }

    .guest-checkout-panel{
        margin-top:0;
        margin-bottom:0;
    }
    .guest-checkout-panel a.checkout-btn{
        margin-top:64px;
    }
}

/* Bootstrap 'md' size, tablets landscape, small screen PCs */
@media (min-width: 992px) {
    /* Cart Global */
    th.cart-price,
    th.cart-retail-price,
    th.cart-qty {
        display: table-cell;
    }

    td.cart-price,
    td.cart-retail-price,
    td.cart-qty {
        display: table-cell;
    }

    th.top-cart-footer-description,
    th.top-cart-footer-price,
    th.top-cart-footer-retail-price {
        display: table-cell;
    }

    th.checkout-cart-footer-padding-price,
    th.checkout-cart-footer-padding-retail-price,
    th.checkout-cart-footer-padding-qty {
        display: table-cell;
    }
    
    p.cart-price,
    p.cart-retail-price,
    p.cart-qty {
        display: none;
    }
}

/* Bootstrap 'lg' size, PCs */
@media (min-width: 1200px) {
    /* Cart Global */
    th.cart-code,
    th.cart-remove {
        display: table-cell;
    }

    td.cart-code,
    td.cart-remove {
        display: table-cell;
    }

    th.top-cart-footer-code,
    th.top-cart-footer-qty {
        display: table-cell;
    }

    th.checkout-cart-footer-padding-code,
    th.checkout-cart-footer-padding-remove {
        display: table-cell;
    }

    p.cart-code,
    p.cart-remove {
        display: none;
    }
}
/*Fix after update*/
.w-slide {
background-size:100% 100%;
}